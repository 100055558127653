import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import Utils from '../../services/utils';
import { NavLink } from 'react-router-dom';

const BonusModal = observer(({show, setShow}) => {
    const {app, user} = useContext(Context);
    const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose} centered className='delivery-stock-modal'>
        <Modal.Body className='delivery-stock-content'>
            <i className='icon-close' onClick={handleClose}></i>
            <h5 className='mb-15'>{app.localizationsItems?.profile_bonus_title}</h5>
            <div className='bonus-modal'>
                <div className='profile-bonus'>
                    <div className='flex profile-bonus-top'>
                        <div>{app.localizationsItems?.bonuses}</div>
                    </div>
                    <div className='flex profile-bonus-middle'>
                        <div className='bonus'>
                            <i className='icon-coin'></i>
                            {Utils.numberWithSpaces(user?.user?.balance)}
                        </div>
                    </div>
                    <div className='flex profile-bonus-bottom'>
                        {user.token && <NavLink to={`/${app.lang}/user/bonuses`} onClick={() => setShow(false)}>
                                {app.localizationsItems?.history}
                            </NavLink>
                            }
                    </div>
                </div>
                <p>{app.localizationsItems?.profile_bonus_content}</p>
            </div>
            <button className='main-btn' onClick={handleClose}>{app.localizationsItems?.sure}</button>
        </Modal.Body>
    </Modal>
  )
})

export default BonusModal