import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const LoyaltyModal = observer(({show, setShow, info}) => {
    const {app, user} = useContext(Context);
    const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose} centered className='delivery-stock-modal'>
        <Modal.Body className='delivery-stock-content'>
            <i className='icon-close' onClick={handleClose}></i>
            <h3 className='mb-15'>{app.localizationsItems?.current_level}: {info.title}</h3>
            <div className='bonus-modal fs-16'>
                <p>{info.description}</p>
            </div>
            <button className='main-btn' onClick={handleClose}>{app.localizationsItems?.sure}</button>
        </Modal.Body>
    </Modal>
  )
})

export default LoyaltyModal